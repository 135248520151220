import React from 'react';
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig';

export const publicRoutes = [
  {
    key: 'login',
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import('views/auth-views/authentication/login')
    ),
  },
  {
    key: 'password-change',
    path: `/password-change`,
    component: React.lazy(() =>
      import('views/auth-views/authentication/reset-password')
    ),
  },
  {
    key: 'register',
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(() =>
      import('views/auth-views/authentication/register')
    ),
  },
  {
    key: 'forgot-password',
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import('views/auth-views/authentication/forgot-password')
    ),
  },
];

export const protectedRoutes = [
  {
    key: 'Dashboard',
    path: `${APP_PREFIX_PATH}/dashboard`,
    component: React.lazy(() => import('views/app-views/home')),
  },
  {
    key: 'subscriptions',
    path: `${APP_PREFIX_PATH}/users/subscriptions`,
    component: React.lazy(() =>
      import('views/app-views/user-list/subscriptions')
    ),
  },
  {
    key: 'admins',
    path: `${APP_PREFIX_PATH}/users/administrators`,
    component: React.lazy(() => import('views/app-views/user-list/admins')),
  },
  {
    key: 'chats',
    path: `${APP_PREFIX_PATH}/chats`,
    component: React.lazy(() => import('views/app-views/Chat-history')),
  },
  {
    key: 'user-details',
    path: `${APP_PREFIX_PATH}/users/subscriptions/details/:id`,
    component: React.lazy(() => import('views/app-views/user-details')),
  },
  {
    key: 'orders',
    path: `${APP_PREFIX_PATH}/orders`,
    component: React.lazy(() => import('views/app-views/orders')),
  },
  {
    key: 'Projects',
    path: `${APP_PREFIX_PATH}/projects`,
    component: React.lazy(() => import('views/app-views/tasks/ProjectList')),
  },
  {
    key: 'A/B Test',
    path: `${APP_PREFIX_PATH}/ab-test`,
    component: React.lazy(() =>
      import('views/app-views/home/statistics/ABTesting.js')
    ),
  },
  {
    key: 'project-details',
    path: `${APP_PREFIX_PATH}/projects/:id`,
    component: React.lazy(() =>
      import('views/app-views/tasks/ProjectDetails.js')
    ),
  },
  {
    key: 'Tasks',
    path: `${APP_PREFIX_PATH}/tasks`,
    component: React.lazy(() => import('views/app-views/tasks/TaskSection')),
  },
];
