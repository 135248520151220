import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  DIR_LTR,
} from "constants/ThemeConstant";
import { io } from "socket.io-client";

export const APP_NAME = "Savy Admin";
export const API_BASE_URL = "https://api.savy.app/api/v1";
export const URL_BASE = "https://api.savy.app/";
//export const API_BASE_URL = "http://localhost:4000/api/v1";
//export const URL_BASE = "http://localhost:4000/";
export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";
export const REDIRECT_URL_KEY = "redirect";
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/dashboard`;
export const UNAUTHENTICATED_ENTRY = "/login";
export const webSOCKET = io(URL_BASE + "subscribe-socket", {
  query: { userId: "adminPanel" },
});
export const THEME_CONFIG = {
  navCollapsed: true,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "en",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: "light",
  direction: DIR_LTR,
  blankLayout: false,
};
